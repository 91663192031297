<template>
  <div>
    <!--    <div>Selected date: {{date}}</div>-->
    <!--    <br/>-->
    <!--    <v-date-picker v-model="date" locale="vi"/>-->
    <CCard>
      <CCardHeader>
        <p class="text-uppercase font-weight-bold text-center">Hệ thống Xúc tiến đầu tư của Bộ Kế hoạch và Đầu tư</p>
        <p class="text-uppercase font-weight-bold text-center">Cục Đầu tư Nước ngoài</p>
      </CCardHeader>
      <CCardBody class="text-center align-content-center">
        <img src="@/assets/images/Logo_FIA_2014.jpg" class="" style="image-rendering: pixelated; object-fit: contain">
      </CCardBody>
    </CCard>
  </div>
</template>

<script>
export default {
  name: 'Dashboard',
  data () {
    return {
      // date: new Date()
    }
  }
}
</script>

<style scoped>

</style>
